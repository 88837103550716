.chatbot {
  background: #000080;
  cursor: auto;
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 36px 0px;
  /* border-radius: 15px; */
  z-index: 999999;
  box-shadow: 0 0 128px 0 32px 64px -48px rgba(0, 0, 0, 0.5);
}

.chatbot header {
  background-image: linear-gradient(
    135deg,
    rgba(42, 39, 218, 0.72) 0%,
    rgba(0, 204, 255, 0.72) 100%
  );
  border-radius: 15px;
  padding: 24px 28px;
  text-align: center;
  position: relative;
}
.headerLogo {
  display: flex;
  align-items: center;
}
.headerLogo h2 {
  margin-left: 20px;
}

.chatbot header h2 {
  color: #fff;
  font-size: 1.4rem;
}
.chatbot header span {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #fff;
  cursor: pointer;
  display: none;
  transform: translateY(-50%);
}
.chatbot .chatbox {
  min-height: 370px;
  max-height: 670px;
  overflow-y: auto;
  padding: 30px 20px;
  background-color: #fff;
}
.chatbox .chat {
  display: flex;
}
.chatbox .incoming {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 15px;
  margin-top: 15px;
}
.chatbox .incoming span {
  height: 35px;
  width: 50px;
  color: #fff;
  align-self: end;
  text-align: center;
  line-height: 32px;
  border-radius: 4px;
  margin-right: 5px;
}
.chatbox .incoming span img {
  border-radius: 50%;
  height: auto;
  max-width: 100%;
}

.chatbox .outgoing {
  flex-direction: row-reverse;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
}

.chatbox .outgoing p {
  position: relative;
  align-self: flex-end;
  width: 100%;
  margin-right: 5px;
  margin-bottom: 10px;
}

.chatbox .outgoing p::before {
  content: "";
  position: absolute;
  left: -1px;
  bottom: -7px;
  width: 0px;
  height: 0px;
  border-left: 14px solid transparent;
  border-top: 10px solid rgb(0, 0, 128);
  margin-left: calc(100% - 12.5px);
  margin-top: -1px;
}

.chatbox .outgoing span {
  height: 40px;
  width: 40px;
  color: #fff;
  align-self: flex-end;
  text-align: center;
  line-height: 32px;
  border-radius: 4px;
  margin: 0px 10px 7px 0px;
}
.chatbox .chat p {
  color: #fff;
  width: auto;
  white-space: pre-wrap;
  font-size: 0.9rem;
  padding: 12px 16px;
  border-radius: 10px 10px 0 10px;
  background-color: #000080;
}
.chatbox .chat p.error {
  color: #721c24;
  background-color: #f8d7da;
}

.chatbox .incoming p {
  text-align: start;
  color: #000;
  background: rgb(240, 242, 247);
  border-radius: 8px 8px 8px 0;
  padding: 10px 16px;
  margin: 2px 0px;
  font-size: 15px;
  line-height: 20px;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 85%;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out 0s;
}

.chatbox .suggestions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
}
.chatbox .suggestions p {
  border: 1px solid #000080;
  text-align: start;
  color: #000;
  cursor: pointer;
  background: rgb(240, 242, 247);
  border-radius: 5px;
  padding: 10px 16px;
  font-size: 15px;
  line-height: 20px;
  overflow-wrap: break-word;
  display: inline-block;
}

.chatbox .suggestions span {
  border: 1px solid #000080;
  text-align: center;
  color: #000;
  cursor: pointer;
  background: rgb(240, 242, 247);
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
  line-height: 20px;
  overflow-wrap: break-word;
  display: inline-block;
}

.chatbox .incoming p::before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -9px;
  width: 0px;
  height: 0px;
  border-right: 14px solid transparent;
  border-top: 10px solid rgb(242, 242, 247);
  margin-top: -1px;
}
.chatbot .chat-input {
  bottom: 0;
  width: 100%;
  /* border-radius: 0px 0px 15px 15px; */
  display: flex;
  gap: 5px;
  background-color: #fff;
  padding: 5px 20px;
  border-top: 1px solid #ccc;
}
.chat-input textarea {
  height: 55px;
  width: 100%;
  border: none;
  outline: none;
  cursor: text;
  color: #000;
  max-height: 100px;
  font-size: 0.95rem;
  resize: none;
  padding: 16px 15px 16px 0;
}
.chat-input span {
  align-self: flex-end;
  height: 55px;
  line-height: 55px;
  color: #08085b;
  font-size: 1.35rem;
  cursor: pointer;
  visibility: hidden;
}
.chat-input textarea:valid ~ span {
  visibility: visible;
}

/* --------------------------- */
.chat-header {
  color: rgb(255, 255, 255);
  /* background: linear-gradient(135deg, #000080 -14%, #b72025 102%); */
  padding: 24px 28px 0px;
  position: relative;
  z-index: 4;
  flex: 0 0 auto;
}

.avatars-wrapper {
  width: 52px;
  height: 52px;
  margin: 0px 18px 0px 0px;
  float: left;
}

.header-ava {
  width: 52px;
  height: 52px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  float: left;
}
.oneline {
  margin-top: 0px;
  min-height: 52px;
  font-size: 19px;
  font-weight: 600;
  color: currentcolor;
  margin: 4px 0px 0px;
  padding: 0px;
  display: inline-block;
  position: relative;
  max-width: calc(100% - 145px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}

.oneline #bot-name {
  font-size: 15px;
}

.chat-header h2 .emoji {
  width: 31px;
  height: 31px;
  margin: 0px 2px -5px;
  user-select: none;
}

.actionIcon {
  float: right;
}

.expandMore,
.showMore {
  font-size: 35px;
  margin-top: 10px;
  cursor: pointer;
}

.offline-message {
  /* background-image: #000080;
  border-top: 2px solid #ffffff; */
  background: #b72025;
  border: 0;
  padding: 14px 28px 20px;
  margin: 18px -28px 0px;
  color: currentcolor;
  width: calc(100% + 56px);
  padding: 14px 28px 20px;
  position: relative;
  background-size: 100% calc(100% + 12px);
  z-index: 1;
}

.offline-message::after {
  content: "";
  position: absolute;
  width: calc(100% + 0px);
  bottom: -8px;
  left: 0px;
  border-image-source: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzIgMTUiPgogIDxwYXRoIGQ9Ik0zNDkuOCAxLjRDMzM0LjUuNCAzMTguNSAwIDMwMiAwaC0yLjVjLTkuMSAwLTE4LjQuMS0yNy44LjQtMzQuNSAxLTY4LjMgMy0xMDIuMyA0LjctMTQgLjUtMjggMS4yLTQxLjUgMS42Qzg0IDcuNyA0MS42IDUuMyAwIDIuMnY4LjRjNDEuNiAzIDg0IDUuMyAxMjguMiA0LjEgMTMuNS0uNCAyNy41LTEuMSA0MS41LTEuNiAzMy45LTEuNyA2Ny44LTMuNiAxMDIuMy00LjcgOS40LS4zIDE4LjctLjQgMjcuOC0uNGgyLjVjMTYuNSAwIDMyLjQuNCA0Ny44IDEuNCA4LjQuMyAxNS42LjcgMjIgMS4yVjIuMmMtNi41LS41LTEzLjgtLjUtMjIuMy0uOHoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==);
  border-image-slice: 0 0 100%;
  border-image-width: 0 0 15px;
  border-image-repeat: stretch;
  border-width: 0px 0px 15px;
  border-bottom-style: solid;
  border-color: initial;
  border-top-style: initial;
  border-left-style: initial;
  border-right-style: initial;
}

/* ----------------------- */

.chatbox .botSuggestion {
  margin: 5px 0px;
  justify-content: flex-end;
}
.botSuggestion span {
  color: #000000;
  border: 2px solid #000080;
  font-size: 1rem;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
}
